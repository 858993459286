
import axios from 'axios'
import { Toast } from 'vant'
import router from '@/router'
import store from '../store/index'

const service = axios.create({
  baseURL: '/api', // 请求url
  timeout: 30000 // 超时处理
  // withCredentials: true // 是否跨域
})

// 添加请求拦截器
service.interceptors.request.use(config => {
  // 在请求发出之前进行一些操作，比如请求头携带内容
  // config.headers['Content-Type'] = 'application/json, text/plain'
  config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
  config.headers['X-Token'] = localStorage.getItem('token') + '.' + 300
  const state = store.state.projectId
  const params = { project_id: state, ...config.params }
  config.params = params
  Toast.loading({
    mask: false,
    duration: 0,
    forbidClick: true,
    message: '加载中'
  })
  return config
}, error => {
  // 请求错误
  Toast.clear()
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(res => {
  Toast.clear()
  return res.data
}, error => {
  Toast.clear()
  const toastOptions = {
    message: error.message,
    duration: '1000'
  }
  switch (error && error.response && error.response.status) {
    case 400:
      toastOptions.message = error.response.data.message
      break
    case 401:
      toastOptions.message = error.response.data.message
      if (router.currentRoute.name === 'orderShow') {
        toastOptions.onClose = () => {
          router.replace({
            name: 'login',
            query: { redirect: router.currentRoute.fullPath }
          })
        }
      } else {
        toastOptions.onClose = () => {
          router.push({ path: `/login` })
        }
      }
      break
    case 403:
      error.message = '拒绝访问'
      break
    case 404:
      error.message = '未找到访问地址'
      break
    case 408:
      error.message = '请求超时'
      break
    case 422:
      toastOptions.message = error.response.data.data
      break
    case 500:
      error.message = '系统错误'
      toastOptions.message = error.message
      break
    case 501:
      error.message = '服务未实现'
      break
    case 502:
      error.message = '系统重启'
      break
    case 503:
      error.message = '服务不可用'
      break
    case 504:
      error.message = '网关超时'
      break
    case 505:
      error.message = 'HTTP版本不受支持'
      break
    default:
  }
  // Do something with response error
  Toast(toastOptions)
  return Promise.reject(error)
})

export default service

